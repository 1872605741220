import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { ChipLiquidityModal, chipLiquidityToast } from "./ChipLiquidityModal";
import {
  useContractsServicesStore,
  useCryptoWalletIntegrationStore,
  useLexStoreById,
} from "../../../store/storeHooks.ts";
import {
  CHAIN_ID_FANTOM_OPERA,
  CHAIN_ID_NO_CHAIN,
  CHAIN_ID_TO_DISPLAY_DATA,
  TChainIds,
} from "../../../constants/chainConstants.ts";
import { OftBridgeUtils } from "../../../services/contractsIntegration/OftBridgeService/OftBridgeUtils.ts";
import {
  etherBnToFloat,
  floatToEtherBn,
  floatToUnitsBn,
  unitsBnToFloat,
} from "../../../utils/bignumbers.ts";
import { ethers } from "ethers";
import {
  toastError,
  toastInfo,
  toastWarning,
} from "../../../ux/toasts/toasting.ts";
import { useAccount, useBalance, useBytecode } from "wagmi";
import { Address } from "viem";
import { observer } from "mobx-react";
import { TPendingLZBridgeTxGist } from "../../../pages/PortfolioPage/pageHooks/chipsPageHooks.ts";
import { useWaitForTx } from "../../../hooks/txWaitingHooks.tsx";
import { numericalDisplay } from "../../../ux/displayCalculations.ts";
import { toastTxError } from "../../../ux/toasts/complexToasting.ts";
import { ChipModeEnums } from "../../../constants/contractEnums.ts";
import { LED_IDS } from "../../../services/leverageDimensionService/leveregeDimensionsParams.ts";
import { SingleLexStore } from "../../../store/multiInstancesStores/LexStore/SingleLexStore.ts";
import { useReadNativeBalanceOnChainId } from "../../../hooks/walletConnectionHooks.ts";
import { useLoadingBridgeTx } from "../../../store/loadingBridgeTxContext.tsx";
import { bnRoundingUtils } from "../../../utils/bnRoundingUtils.ts";

interface IProps {
  open: boolean;
  closeModal: () => void;
  lexId: string;
  pendingBridgeTxGists: TPendingLZBridgeTxGist[];
  setPendingBridgeTx: (pendingBridgeTxGist: TPendingLZBridgeTxGist[]) => void;
  onChangeLexId: (lexId: string) => void;
}

const useStyles = makeStyles()((theme) => ({}));

export const ConnectedChipInLiquidityModal = observer<React.FC<IProps>>(
  (props) => {
    const { classes } = useStyles();
    const {
      open,
      closeModal,
      lexId,
      setPendingBridgeTx,
      pendingBridgeTxGists,
      onChangeLexId,
    } = props;

    const lexStore = useLexStoreById(lexId);
    const contractServicesStore = useContractsServicesStore();
    const cryptoWalletConnectionStore = useCryptoWalletIntegrationStore();
    const { setLoadingBridgeTxGists } = useLoadingBridgeTx();
    const [isWaitingForTx, setIsWaitingForTx] = useState(false);
    const [assetAmountInString, setAssetAmountInString] = useState("");

    const { chainId: walletChainId } = useAccount();
    const isConnectedToProperChain = walletChainId === lexStore?.sourceChainId;

    // NOTE : Using this flag to wait a few more seconds after approving to make sure the UX is made
    const [
      isWaitingExtraFewSecondsForApprovalUx,
      setIsWaitingExtraFewSecondsForApprovalUx,
    ] = useState(false);

    const [wasApproveTxSent, setWasApproveTxSent] = useState(false);
    const {
      setTxToWaitForAndCallbacks: setApproveTxHashAndaCallbacks,
      isLoading: isWaitingForApproveTx,
      isFetched: wasApproveTxMined,
    } = useWaitForTx(
      lexStore?.sourceChainId ?? cryptoWalletConnectionStore.chainId,
    );

    const userAddress = cryptoWalletConnectionStore.mainAddress;

    const isWrappedNative = lexStore?.id == LED_IDS.FANTOM_ENGINE_FANTOM_FTM;

    const isOFTChip = lexStore?.chipMode === ChipModeEnums.REMOTE;
    const isEngineChip = lexStore?.chipMode === ChipModeEnums.LOCAL;

    const bytecode = useBytecode({
      address: userAddress as Address,
    });
    const isContractAccount = !!bytecode.data;
    const alertContractAccountWarning = useCallback(() => {
      toastWarning(
        `You seem to be using a contract address ${userAddress}. Please use a wallet address.`,
      );
    }, [userAddress]);

    // console.log(`!!!!! bytecode is loading : ${bytecode.isLoading}`);
    // console.log(`!!!!! bytecode : ${bytecode.data}`);

    const approveCallback = useCallback(async () => {
      if (isContractAccount) {
        alertContractAccountWarning();
        return;
      }

      if (lexStore) {
        const erc20Service = contractServicesStore.buildErc20Service(
          lexStore.sourceAssetParameters.address,
          lexStore.sourceChainId,
        );

        const approveTargetAddress = isOFTChip
          ? lexStore.oftChipAdapterOnSourceAddress
          : lexStore.chipAssetParams.address;

        const conTx = await erc20Service.approve(
          approveTargetAddress,
          ethers.MaxUint256,
        );
        setWasApproveTxSent(true);
        setIsWaitingExtraFewSecondsForApprovalUx(true);
        setApproveTxHashAndaCallbacks(conTx.hash, () => async () => {
          await lexStore?.refreshDataAfterTx(true);
        });
        const receipt = await conTx.wait();
        toastInfo(`Approved in tx ${receipt?.hash}`);

        void lexStore.refreshDataAfterTx();
      }
    }, [
      alertContractAccountWarning,
      contractServicesStore,
      isContractAccount,
      isOFTChip,
      lexStore,
      setApproveTxHashAndaCallbacks,
    ]);

    const sourceSymbol = lexStore?.sourceAssetParameters.symbol ?? "";
    const nativeCoinSymbol = lexStore
      ? CHAIN_ID_TO_DISPLAY_DATA[lexStore.sourceChainId].nativeCoinSymbol
      : "";

    const chainId = lexStore
      ? (CHAIN_ID_TO_DISPLAY_DATA[lexStore.sourceChainId].id as TChainIds)
      : undefined;

    // TODO : Unify this 'loading'/'error' into a proper hook/system
    const [isLoading, setIsLoading] = useState(false);

    // TODO : CRITICAL : Add reading logic after deploying new version
    const [nativeActionFeeInUnits, setNativeActionFeeInUnits] = useState(0);

    // NOTE : Reading native fee for OftChips chipIn
    useEffect(() => {
      // NOTE : Assumes gas cost will be the same regardless of actual transfer amount
      if (lexStore && isOFTChip) {
        const oftChipAdapterService =
          contractServicesStore.buildOftChipAdapterService(
            lexStore.oftChipAdapterOnSourceAddress,
            lexStore.sourceChainId,
          );

        const demoSendParam = OftBridgeUtils.buildSendParamForOftAdapter(
          lexStore.engineChainId,
          lexStore.activeUserStore.accountAddress,
          1n,
          lexStore.sourceAssetParameters.decimals,
        );

        setIsLoading(true);
        oftChipAdapterService
          .readNativeSendFee(demoSendParam)
          .then((nativeFee) => {
            setNativeActionFeeInUnits(etherBnToFloat(nativeFee));
          })
          .catch((e) => console.error(`Error readNativeSendFee : ${e} `))
          .finally(() => {
            setIsLoading(false);
          });
      }
    }, [contractServicesStore, isOFTChip, lexStore]);

    const { nativeBalance: rawNativeBalance } = useReadNativeBalanceOnChainId(
      chainId ?? CHAIN_ID_FANTOM_OPERA,
      userAddress,
    );
    const nativeBalanceRes = useBalance({
      address: userAddress as Address,
    });
    const [nativeBalanceInUnits, setNativeBalanceInUnits] = useState(0);

    const isNativeBalanceReadingError = nativeBalanceRes.isError;
    const nativeBalanceErrMsg = nativeBalanceRes.error;

    useEffect(() => {
      if (isNativeBalanceReadingError) {
        console.error(
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `Error reading native balance via viem : ${nativeBalanceErrMsg}`,
        );
        setNativeBalanceInUnits(etherBnToFloat(rawNativeBalance));
      } else {
        const wagmiBalance = nativeBalanceRes.data?.value ?? 0n;
        setNativeBalanceInUnits(
          etherBnToFloat(wagmiBalance > 0n ? wagmiBalance : rawNativeBalance),
        );
      }
    }, [
      isNativeBalanceReadingError,
      nativeBalanceErrMsg,
      nativeBalanceRes.data?.value,
      rawNativeBalance,
      setNativeBalanceInUnits,
    ]);

    const nativeBalanceToUse =
      nativeBalanceRes.data?.value ?? rawNativeBalance ?? 0n;
    const underlyingBalanceOnSourceChainBn = isWrappedNative
      ? nativeBalanceToUse
      : (lexStore?.activeUserStore.account_underlyingInWalletOnSourceChain ??
        0n);

    // No asset action fee for chip in
    const assetActionFeeInUnits = 0;

    const accountSourceInfoRead =
      lexStore?.activeUserStore.account_sourceChainInfoLoaded;

    // Note : Quick-n-Dirty : After an 'approve' tx, this condition saves a few seconds of waiting to read the new state
    //                        and offers a better UX
    const shouldAssumeApproveWasMade = wasApproveTxSent && wasApproveTxMined;

    // console.log(`wasApproveTxSent: ${wasApproveTxSent}`);
    // console.log(`wasApproveTxMined: ${wasApproveTxMined}`);
    // console.log(`shouldAssumeApproveWasMade: ${shouldAssumeApproveWasMade}`);

    const account_allowanceForAdapterOnSourceChain =
      lexStore?.activeUserStore.account_allowanceForAdapterOnSourceChain ?? 0n;
    const account_allowanceForChipOnEngineChain =
      lexStore?.activeUserStore.account_allowanceForChipOnEngineChain ?? 0n;
    const sourceAssetDecimals = lexStore?.sourceAssetParameters.decimals ?? 18;

    const relevantAllowance = isOFTChip
      ? account_allowanceForAdapterOnSourceChain
      : account_allowanceForChipOnEngineChain;
    const allowanceInUnits = unitsBnToFloat(
      relevantAllowance,
      sourceAssetDecimals,
    );
    const isMaxAllowanceGiven = relevantAllowance > 1_000_000_000;

    const needToApprove = useMemo(() => {
      if (isWrappedNative) {
        return false;
      } else if (isMaxAllowanceGiven) {
        return false;
      } else if (relevantAllowance == 0n) {
        return true;
      } else if (allowanceInUnits < Number(assetAmountInString || 0)) {
        return true;
      } else {
        return false;
      }
    }, [
      allowanceInUnits,
      assetAmountInString,
      isMaxAllowanceGiven,
      isWrappedNative,
      relevantAllowance,
    ]);

    // const needToApprove = isWrappedNative
    //   ? false
    //   : (lexStore?.activeUserStore.account_allowanceForAdapterOnSourceChain ==
    //       0n &&
    //       lexStore.activeUserStore.account_allowanceForChipOnEngineChain ==
    //         0n) ||
    //     chipsBnToUnits(
    //       lexStore?.activeUserStore.account_allowanceForAdapterOnSourceChain ??
    //         0n,
    //     ) < Number(assetAmountInString || 0);
    // && !(wasApproveTxSent ? wasApproveTxMined : false);

    useEffect(() => {
      if (isWaitingExtraFewSecondsForApprovalUx && !needToApprove) {
        // setTimeout(() => {
        setIsWaitingExtraFewSecondsForApprovalUx(false);
        // }, 4 * 1000);
      }
    }, [
      isWaitingExtraFewSecondsForApprovalUx,
      needToApprove,
      shouldAssumeApproveWasMade,
    ]);

    // TODO : CRITICAL : Move to a proper config
    const nativeCoinDisplayDecimals = nativeCoinSymbol == "ETH" ? 10 : 5;

    let availableAmountInUnits = isWrappedNative
      ? nativeBalanceInUnits
      : (lexStore?.activeUserStore
          .accountUnderlyingBalanceInWalletOnSourceChainInUnits ?? 0);

    if (isOFTChip && nativeCoinSymbol === sourceSymbol) {
      availableAmountInUnits = availableAmountInUnits - nativeActionFeeInUnits;
    }

    // console.log(
    //   stringifyObject(
    //     {
    //       isLoading,
    //       accountSourceInfoRead,
    //       isWaitingForApproveTx,
    //       isWaitingExtraFewSecondsForApprovalUx,
    //     },
    //     3,
    //   ),
    // );
    const isLoadingFeesData =
      isLoading ||
      !accountSourceInfoRead ||
      isWaitingForApproveTx ||
      isWaitingExtraFewSecondsForApprovalUx;

    // console.log(`isLoadingFeesData ${isLoadingFeesData}`);

    const oftChipMinntFunction = useCallback(
      async (
        lexStore: SingleLexStore,
        signerAddress: string,
        amountInUnits: number,
      ) => {
        if (signerAddress !== userAddress) {
          throw new Error(
            `Signer address ${signerAddress} does not match user address ${userAddress}`,
          );
        }

        if (isContractAccount) {
          alertContractAccountWarning();
          return;
        }

        const oftChipAdapterService = lexStore.freshOFTChipAdapterService;

        const sourceTokenSymbol = lexStore.sourceAssetParameters.symbol;
        const sourceChainId = lexStore.sourceChainId;

        const sourceTokenDecimals = lexStore.sourceAssetParameters.decimals;
        const amountScaled = floatToUnitsBn(amountInUnits, sourceTokenDecimals);
        const safeAmount = bnRoundingUtils.floorPossiblyExtraDustAmount(
          underlyingBalanceOnSourceChainBn,
          amountScaled,
        );

        const sendParam = OftBridgeUtils.buildSendParamForOftAdapter(
          lexStore.engineChainId,
          signerAddress,
          safeAmount,
          sourceTokenDecimals,
        );

        const nativeFeeToPay =
          await oftChipAdapterService.readNativeSendFee(sendParam);

        setIsWaitingForTx(true);
        setLoadingBridgeTxGists(lexStore.id, true);
        try {
          const conTx = await oftChipAdapterService.chipIn(
            sendParam,
            nativeFeeToPay,
            signerAddress,
          );
          chipLiquidityToast.showToast(lexStore.id);
          const receipt = await conTx.wait();

          //toastInfo(`ChipIn Request in tx ${receipt?.hash}`);
          const currentTxGits = {
            lexId: lexStore.id,
            assetAmountInUnits: amountInUnits,
            assetSymbol: sourceTokenSymbol,
            sourceChainEid: sourceChainId,
            txHash: receipt?.hash ?? "",
            actionName: "Deposit",
            displayMessage: `Depositing ${numericalDisplay(amountInUnits, 3)} ${lexStore.sourceAssetParameters.symbol}`,
          };

          setPendingBridgeTx([...pendingBridgeTxGists, currentTxGits]);

          void lexStore.refreshDataAfterTx();

          closeModal();
        } catch (e) {
          toastTxError(e);
          console.error(e);
        } finally {
          setIsWaitingForTx(false);
          setLoadingBridgeTxGists(lexStore.id, false);
        }
      },
      [
        userAddress,
        isContractAccount,
        underlyingBalanceOnSourceChainBn,
        setLoadingBridgeTxGists,
        alertContractAccountWarning,
        setPendingBridgeTx,
        pendingBridgeTxGists,
        closeModal,
      ],
    );

    const mintEngineChipForNativeViaWrapperFunction = useCallback(
      async (lexStore: SingleLexStore, amountInUnits: number) => {
        const wrappedNativeEngineChipHelperService =
          lexStore.freshWrappedNativeEngineChipHelperService;

        const nativeAmountScaled = floatToEtherBn(amountInUnits);
        const nativeAmountScaledSafe =
          bnRoundingUtils.floorPossiblyExtraDustAmount(
            underlyingBalanceOnSourceChainBn,
            nativeAmountScaled,
          );

        if (isContractAccount) {
          alertContractAccountWarning();
          return;
        }

        try {
          setIsWaitingForTx(true);

          const conTx =
            await wrappedNativeEngineChipHelperService.wrapNativeAndMintEngineChip(
              nativeAmountScaledSafe,
            );
          const receipt = await conTx.wait();

          toastInfo(`Native ChipIn Request in tx ${receipt?.hash}`);

          void lexStore.refreshDataAfterTx();

          closeModal();
        } catch (e) {
          toastTxError(e);
          console.error(e);
        } finally {
          setIsWaitingForTx(false);
        }
      },
      [
        alertContractAccountWarning,
        closeModal,
        isContractAccount,
        underlyingBalanceOnSourceChainBn,
      ],
    );

    const mintEngineChipForErc20 = useCallback(
      async (
        lexStore: SingleLexStore,
        signerAddress: string,
        amountInUnits: number,
      ) => {
        const engineChipService = lexStore.freshEngineChipService;

        const sourceTokenDecimals = lexStore.sourceAssetParameters.decimals;
        const amountScaled = floatToUnitsBn(amountInUnits, sourceTokenDecimals);
        const amountScaledSafe = bnRoundingUtils.floorPossiblyExtraDustAmount(
          underlyingBalanceOnSourceChainBn,
          amountScaled,
        );

        if (isContractAccount) {
          alertContractAccountWarning();
          return;
        }

        try {
          setIsWaitingForTx(true);

          const conTx = await engineChipService.mintChip(
            signerAddress,
            amountScaledSafe,
          );
          const receipt = await conTx.wait();

          void lexStore.refreshDataAfterTx();

          toastInfo(`Mint Chip Request in tx ${receipt?.hash}`);

          closeModal();
        } catch (e) {
          toastTxError(e);
          console.error(e);
        } finally {
          setIsWaitingForTx(false);
        }
      },
      [
        alertContractAccountWarning,
        closeModal,
        isContractAccount,
        underlyingBalanceOnSourceChainBn,
      ],
    );

    const chipInCallback: (amountInUnits: number) => Promise<void> =
      useCallback(
        async (amountInUnits: number) => {
          if (amountInUnits == 0) {
            toastWarning(`0 amount`);
            return;
          }

          if (isContractAccount) {
            alertContractAccountWarning();
            return;
          }

          if (lexStore) {
            const signerAddress =
              (await cryptoWalletConnectionStore.getChainSigner?.getAddress()) ??
              "NON";

            const sourceTokenDecimals = lexStore.sourceAssetParameters.decimals;
            const amountScaled = floatToUnitsBn(
              amountInUnits,
              sourceTokenDecimals,
            );
            const safeAmountToUse =
              bnRoundingUtils.floorPossiblyExtraDustAmount(0n, amountScaled);

            if (!isConnectedToProperChain) {
              return toastError(
                `Connected to wrong chain ! Connected to ${walletChainId} instead of ${lexStore.engineChainId}`,
              );
            } else if (isOFTChip) {
              await oftChipMinntFunction(
                lexStore,
                signerAddress,
                amountInUnits,
              );
            } else if (isEngineChip) {
              if (isWrappedNative) {
                await mintEngineChipForNativeViaWrapperFunction(
                  lexStore,
                  amountInUnits,
                );

                void lexStore.refreshDataAfterTx();
              } else {
                await mintEngineChipForErc20(
                  lexStore,
                  signerAddress,
                  amountInUnits,
                );
              }

              // setPendingBridgeTx({
              //   lexId: lexStore.id,
              //   assetAmountInUnits: amountInUnits,
              //   assetSymbol: sourceTokenSymbol,
              //   sourceChainEid: sourceChainId,
              //   txHash: receipt?.hash ?? "",
              //   actionName: "Deposit",
              //   displayMessage: `Depositing ${numericalDisplay(amountInUnits, 3)} ${lexStore.sourceAssetParameters.symbol}`,
              // });

              void lexStore.refreshDataAfterTx();

              closeModal();
            } else {
              chipLiquidityToast.updateToast(
                lexStore.id,
                chipLiquidityToast.getStep(lexStore.id),
                `No ChipIn logic defined for chip mode ${lexStore.chipMode}`,
              );
              console.error(
                `No ChipIn logic defined for chip mode ${lexStore.chipMode}`,
              );
            }
          }
        },
        [
          alertContractAccountWarning,
          closeModal,
          cryptoWalletConnectionStore.getChainSigner,
          isConnectedToProperChain,
          isContractAccount,
          isEngineChip,
          isOFTChip,
          isWrappedNative,
          lexStore,
          mintEngineChipForErc20,
          mintEngineChipForNativeViaWrapperFunction,
          oftChipMinntFunction,
          walletChainId,
        ],
      );

    return (
      <ChipLiquidityModal
        open={open}
        closeModal={closeModal}
        assetSymbol={sourceSymbol}
        nativeCoinSymbol={nativeCoinSymbol}
        chainId={chainId ?? CHAIN_ID_NO_CHAIN}
        approveCallback={approveCallback}
        actionCallback={chipInCallback}
        needToApprove={needToApprove}
        isWaitingForTx={isWaitingForTx}
        isLoadingFeesData={isLoadingFeesData}
        nativeActionFeeInUnits={nativeActionFeeInUnits}
        assetActionFeeInUnits={assetActionFeeInUnits}
        availableAmountInUnits={availableAmountInUnits}
        nativeBalanceInUnits={nativeBalanceInUnits}
        title={"Deposit"}
        actionName={"Deposit"}
        nativeCoinDisplayDecimals={nativeCoinDisplayDecimals}
        isEngineChip={isEngineChip}
        onChangeLexId={onChangeLexId}
        onSetAssetAmountInString={setAssetAmountInString}
        assetAmountInString={assetAmountInString}
      />
    );
  },
);
